import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  ScheduledReport,
  ScheduledReportParameters,
  ScheduledReportViewResponse,
} from '@/features/om27/types/om27.types';

export function getScheduledReportList(): AxiosPromise<ScheduledReport[]> {
  return axios.get(URLs.RpaOpsScheduledReportList);
}

export function createNewScheduledReport(data: ScheduledReport): AxiosPromise<any> {
  return axios.post(URLs.RpaOpsScheduledReportCreate, data);
}

export function editScheduledReport(data: ScheduledReport): AxiosPromise<any> {
  return axios.put(URLs.RpaOpsScheduledReportEdit, data, {
    params: {
      reportName: data.reportName,
    },
  });
}

export function viewReport(
  reportName: string,
  reportParameters: ScheduledReportParameters
): AxiosPromise<ScheduledReportViewResponse> {
  return axios.get(URLs.RpaOpsScheduledReportView, {
    params: {
      reportName,
      ...reportParameters,
    },
  });
}

export function deleteScheduledReport(reportName: string): AxiosPromise<any> {
  return axios.delete(URLs.RpaOpsScheduledReportDelete, {
    params: {
      reportName,
    },
  });
}
