
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import FullScreenForm from '@/components/FullScreenForm.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { RouteNames } from '@/constants';
import { viewReport } from '@/features/om27/api/scheduled-reports.api';
import { BotColumn } from '@/features/om27/types/logdata.types';
import TablePagination from '@/components/table/TablePagination.vue';

@Component({
  components: {
    FullScreenForm,
    Grid,
    GridCell,
    TablePagination,
  },
})
export default class ScheduledReportingCreate extends Vue {

  closeRoute: RawLocation = {
    name: RouteNames.RpaOpsScheduledReporting,
  };

  page = 1;
  perPage = 25;
  reportData: any = null;

  get columns(): BotColumn<unknown>[] {
    if (!this.reportData || !this.reportData.length) {
      return [];
    }
    const [row] = this.reportData;
    if (!row) {
      return [];
    }

    let columnTitles = Object.keys(row);
    const str = (v: any) => String(v).toLocaleLowerCase();
    let columns = columnTitles.map((title) => ({
      field: title,
      title: title,
      key: title,
    } as BotColumn<unknown>));

    if (
      columnTitles.findIndex((title) => str(title) === 'url') > -1
      && columnTitles.findIndex((title) => str(title) === 'sign_off_id') > -1
    ) {
      columns = columns.filter((col) => str(col.key) !== 'url');
      columns = columns.map((col) => {
        if (str(col.key) === 'sign_off_id') {
          return {
            ...col,
            renderBodyCell: ({ row, column }: { row: any, column: BotColumn<unknown> }, h) => {
              const signOffId = row[column.key];
              const url = row['URL'] || row['url'];
              return <a href={url} target="_blank">{signOffId}</a>;
            },
          };
        }
        return col;
      });
    }
    return columns;
  }

  get rows() {
    return this.reportData || [];
  }

  get paginatedRows() {
    let start = (this.page - 1) * this.perPage;
    if (start > this.rows.length) {
      this.page = Math.ceil(this.rows.length / this.perPage);
      start = (this.page - 1) * this.perPage;
    }
    const end = start + this.perPage;
    return this.rows.slice(start, end);
  }

  async fetchReport() {
    const { reportName } = this.$route.params;
    const { mudId, endDateUTC, startDateUTC } = this.$route.query;
    const { data } = await viewReport(reportName, {
      mudId: String(mudId),
      startDateUTC: String(startDateUTC),
      endDateUTC: String(endDateUTC),
    });
    return data;
  }

  async created() {
    const { reportData } = await this.fetchReport();
    this.reportData = reportData;
  }
}
